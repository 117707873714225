import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import { Dropdown } from "react-bootstrap";
import Search from "./common/Search";
// import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/header.js";

class Header extends Component {
  render() {
    return (
      <Styles>
        {/* Topbar */}
        <section className="top-bar">
          <Container>
            <Row>
              <Col lg="6" md="5">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <i className="las la-map-marker"></i>DUTTAPUKUR DIST - NORTH 24 PARGANAS, PIN -
                      743248.
                    </li>
                    <li className="list-inline-item">
                      <Link to={ "/faq"}>
                        Have Questions
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="6" md="7">
                <div className="bar-right d-flex justify-content-end">
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area */}
        <section className="logo-area" style={{
          width: "90%",
          margin: "auto",
        }} >
            <Row>
              <Col md="5">
                <div className="logo">
                  <Link to={ "/"} style={{
                    display : "flex",
                    alignItems: "flex-start",
                  }}>
                    <img
                      src={
                        "https://res.cloudinary.com/da5mimn3m/image/upload/v1711188157/mlc/COLLEGE_LOGO_xom5le.png"
                      }
                      alt=""
                      style={{
                        width: "90px",
                        height: "90px",
                      }}
                    />
                    <div>
                    <p style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                      marginBottom: "-7px",
                      letterSpacing: "4px",
                    }}>
                    Monalisa B.Ed College 
                    </p>
                    <p style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                      letterSpacing: "4px",
                    }}>
                    and Higher Education
                    </p>
                    <p style={{
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                    }}>
                    RECOGNISED BY NCTE, GOVT. OF INDIA
                    </p>
                    <p style={{
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                    }}>
                    AFFILIATED TO BSAEU (Erstwhile WBUTTEPA) & WBBPE
                    </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md="7">
                <div className="logo-contact-box d-flex justify-content-end">
                  <div className="emcontact-box d-flex">
                    <div className="box-icon">
                      <i className="flaticon-phone-call"></i>
                    </div>
                    <div className="box-content">
                      <p>Call Us Now</p>
                      <span>(+91) 9836922333</span>
                    </div>
                  </div>
                  <div className="emcontact-box d-flex">
                    <div className="box-icon">
                      <i className="flaticon-envelope"></i>
                    </div>
                    <div className="box-content">
                      <p>Enquire Us</p>
                      <span>monalisab.edcollege@gmail.com</span>
                    </div>
                  </div>
                  <div className="apply-btn">
                    <Link to={ "/registration"}>
                      <i className="las la-clipboard-list"></i>Apply Now
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
        </section>

        {/* Navbar */}
        <section className="main-menu">
          <Container>
            <Row>
              <Col md="12">
                <div className="main-menu-box">
                  <div className="menu-box d-flex justify-content-between">
                    <ul className="nav menu-nav">
                      <li className="nav-item dropdown active">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          HOME
                          {/* Home <i className="las la-angle-down"></i> */}
                        </Link>
                        {/* <ul className="dropdown list-unstyled">
                          <li className="nav-item active">
                            <Link
                              className="nav-link"
                              to={ "/"}
                            >
                              Home Style 1
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/home-two"}
                            >
                              Home Style 2
                            </Link>
                          </li>
                        </ul> */}
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          About us <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/about"}
                            >
                              About Monalisa
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/gallery"}
                            >
                              Gallery
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/login"}
                            >
                              Log In
                            </Link>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/registration"}
                            >
                              Registration
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/contact"}
                            >
                              Contact
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/faq"}
                            >
                              Faq
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Courses
                          <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/course-list"}
                            >
                              Course List
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/course-details"}
                            >
                              Course Details
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Faculty <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/instructor"}
                            >
                              Instructors
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                 "/instructor-details"
                              }
                            >
                              Instructor Details
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Event <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/events"}
                            >
                              Events
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/event-details"}
                            >
                              Event Details
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Blog <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-classic"}
                            >
                              Blog Classic
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-grid"}
                            >
                              Blog Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-details"}
                            >
                              Blog Details
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Shop <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/products"}
                            >
                              Products
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/product-details"}
                            >
                              Product Details
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/cart"}
                            >
                              Cart
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                 
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    );
  }
}

export default Header;
