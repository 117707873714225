import React, { Component } from 'react';
import Datas from '../data/footer/footer.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
    render() {
        return (
          <Styles>
            {/* Footer Area */}
            <footer
              className="footer1"
              style={{
                backgroundImage: `url(assets/images/${
                   Datas.backgroundImage
                })`,
              }}
            >
              <Container>
                <Row>
                  <Col md="4">
                    <div className="footer-logo-info">
                      <img
                        src={
                          
                          "https://res.cloudinary.com/da5mimn3m/image/upload/v1711188157/mlc/COLLEGE_LOGO_xom5le.png"
                        }
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        className="img-fluid"
                      />
                      <p>
                        We Have Experienced Professionals & We Do Our Best To
                        Achieve Your Goal. Your Happiness Is Our First Priority.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="las la-map-marker"></i>VILL + PO -
                          KASHIMPUR, DUTTAPUKUR DIST - NORTH 24 PARGANAS, PIN -
                          743248
                        </li>
                        <li>
                          <i className="las la-envelope"></i>
                          carriermonalisab.edcollege@gmail.com
                        </li>
                        <li>
                          <i className="las la-phone"></i>+91 9836922333
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col md="4">
                    <div className="f-links">
                      <h5>Useful Links</h5>
                      <ul className="list-unstyled">
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>General Info
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Help Center
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Our Services
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Online Support
                          </Link>
                        </li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>General Info
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Help Center
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Our Services
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={ "/"}>
                            <i className="las la-angle-right"></i>Online Support
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col> */}
                  <Col md="4">
                    <div className="f-post">
                      <h5>Recent Post</h5>
                      <div className="post-box d-flex">
                        <div className="post-img">
                          <img
                            src={
                              
                              "/assets/images/Basanta.jpeg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="post-content">
                          <Link to={ "/blog-details"}>
                            Basant Utsav celebrates spring with vibrant colors,
                            music, and cultural festivities.
                          </Link>
                          <span>feb 14, 2024</span>
                        </div>
                      </div>
                      <div className="post-box d-flex">
                        <div className="post-img">
                          <img
                            src={
                              
                              "/assets/images/Yoga.jpeg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="post-content">
                          <Link to={ "/blog-details"}>
                            Yoga promotes wellness, harmony, and mindfulness
                            through physical and mental practices.
                          </Link>
                          <span>jun 21, 2023</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>

            {/* Copyright Area */}
            <section className="copyright-area">
              <Container>
                <Row>
                  <Col md="6">
                    <div className="copy-text">
                      <p>
                        Copyright &copy; {
                          new Date().getFullYear()
                        } | Designed With{" "}
                        <i className="las la-heart"></i> by{" "}
                        <a
                          href={ "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MyLeading Campus
                        </a>
                      </p>
                    </div>
                  </Col>
                  <Col md="6" className="text-right">
                    <ul className="social list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a href={ "/"}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={ "/"}>
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={ "/"}>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={ "/"}>
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={ "/"}>
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>

              {/* Back To Top */}
              <BackToTop />
            </section>
          </Styles>
        );
    }
}

export default Footer
