import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import { Dropdown } from "react-bootstrap";
import Search from './common/Search';
// import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";

class HeaderTwo extends Component {
    render() {
        return (
          <Styles>
            {/* Topbar 2 */}
            <section className="top-bar2">
              <Container>
                <Row>
                  <Col lg="7" md="9">
                    <div className="bar-left">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-phone"></i>+91 9836922333
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-envelope"></i>
                          monalisab.edcollege@gmail.com
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-map-marker"></i> Kolkata, PIN - 743248.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <Col lg="5" md="3">
                    <div className="bar-right d-flex justify-content-end">
                      <ul className="list-unstyled list-inline bar-lang">
                        <li className="list-inline-item">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <img
                                src={
                                  
                                  "/assets/images/us.png"
                                }
                                alt=""
                              />
                              English<i className="las la-angle-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="ul">
                              <Dropdown.Item as="li">
                                <img
                                  src={
                                    
                                    "/assets/images/us.png"
                                  }
                                  alt=""
                                />{" "}
                                English
                              </Dropdown.Item>
                              <Dropdown.Item as="li">
                                <img
                                  src={
                                    
                                    "/assets/images/fra.png"
                                  }
                                  alt=""
                                />{" "}
                                French
                              </Dropdown.Item>
                              <Dropdown.Item as="li">
                                <img
                                  src={
                                    
                                    "/assets/images/ger.png"
                                  }
                                  alt=""
                                />{" "}
                                German
                              </Dropdown.Item>
                              <Dropdown.Item as="li">
                                <img
                                  src={
                                    
                                    "/assets/images/spa.png"
                                  }
                                  alt=""
                                />{" "}
                                Spanish
                              </Dropdown.Item>
                              <Dropdown.Item as="li">
                                <img
                                  src={
                                    
                                    "/assets/images/bra.png"
                                  }
                                  alt=""
                                />{" "}
                                Brazilian
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                      <ul className="list-unstyled list-inline bar-social">
                        <li className="list-inline-item">
                          <a href={ "/"}>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={ "/"}>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={ "/"}>
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={ "/"}>
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>

                      <ul className="list-unstyled list-inline sidebar-button">
                        <li className="list-inline-item nav-item side-box">
                          <Sidebar />
                        </li>
                      </ul>
                    </div>
                  </Col> */}
                </Row>
              </Container>
            </section>

            {/* Logo Area 2 */}
            <section className="logo-area2">
              <Container>
                <Row>
                  <Col md="3">
                    <div className="logo">
                      <Link to={ "/"}>
                        <img
                          src={
                            
                            "https://res.cloudinary.com/da5mimn3m/image/upload/v1711188157/mlc/COLLEGE_LOGO_xom5le.png"
                          }
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col md="9">
                    <div className="menu-box d-flex justify-content-end">
                      <ul className="nav menu-nav">
                        <li className="nav-item dropdown active">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Home
                            {/* <i className="las la-angle-down"></i> */}
                          </Link>
                          {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={ "/"}>Home Style 1</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/home-two"}>Home Style 2</Link></li>
                                            </ul> */}
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Pages <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/about"}
                              >
                                About Us
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/gallery"}
                              >
                                Gallery
                              </Link>
                            </li>
                            {/* <li className="nav-item"><Link className="nav-link" to={ "/login"}>Log In</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/registration"}>Registration</Link></li> */}
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/contact"}
                              >
                                Contact
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/faq"}
                              >
                                Faq
                              </Link>
                            </li>
                            {/* <li className="nav-item"><Link className="nav-link" to={ "/404"}>404</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/coming-soon"}>Coming Soon</Link></li> */}
                          </ul>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Courses <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            {/* <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/course-grid"}
                              >
                                Course Grid
                              </Link>
                            </li> */}
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/course-list"}
                              >
                                Course List
                              </Link>
                            </li>
                            {/* <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/course-details"}
                              >
                                Course Details
                              </Link>
                            </li> */}
                          </ul>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Instructor <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/instructor"}
                              >
                                Instructors
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={
                                   "/instructor-details"
                                }
                              >
                                Instructor Details
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Event <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/events"}
                              >
                                Events
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <Link
                                className="nav-link"
                                to={ "/event-details"}
                              >
                                Event Details
                              </Link> */}
                            </li>
                          </ul>
                        </li>
                        {/* <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Blog <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/blog-classic"}
                              >
                                Blog Classic
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/blog-grid"}
                              >
                                Blog Grid
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/blog-details"}
                              >
                                Blog Details
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Shop <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/products"}
                              >
                                Products
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/product-details"}
                              >
                                Product Details
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/cart"}
                              >
                                Cart
                              </Link>
                            </li>
                          </ul>
                        </li> */}
                      </ul>
                      <div className="search-box">
                        <Search />
                      </div>
                      <div className="apply-btn">
                        <Link to={ "/registration"}>
                          <i className="las la-clipboard-list"></i>Apply Now
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>

            {/* Sticky Menu */}
            <StickyMenu />

            {/* Mobile Menu */}
            <MobileMenu />
          </Styles>
        );
    }
}

export default HeaderTwo
